import React from 'react'
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import ContactBar from '../../components/ContactBar';
import SingleImage from '../../library/SingleImage';
import Layout from '../../components/Layout';

const MoxaPage = (props) => (
  <Layout location={props.location}>
    <div className="page offer">
      <Helmet>
        <title>Angebot, Behandlungsmethoden</title>
        <meta name="description" content="Akupunktur, Kräutermedizin, Moxa, Schröpfen, Mein Angebot, Behandlungsmethoden" />
        <meta property="og:title" content="Angebot, Behandlungsmethoden" />
        <meta property="og:description" content="Akupunktur, Kräutermedizin, Moxa, Schröpfen, Mein Angebot, Behandlungsmethoden" />
        {/* <meta name="keywords" content="KEYWORDs§" /> */}
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Moxa</h1>
        <SingleImage image={props.data.moxa.fluid} />
        <p>Bei Moxa, oder auch Moxibustion, handelt es sich um eine punktuelle Wärmebehandlung der Haut.</p>
        <p>Hierbei wird getrocknetes Beifusskraut in Form einer Zigarre, oder als Kegel direkt auf der Akupunkturnadel, abgebrannt. Dem Körper wird eine angenehm, wohlige Wärme zugeführt, der Qi- und Blutfluss wird angeregt, sowie krankmachende Faktoren wie Kälte, Wind oder Schleim werden aus dem Körper vertrieben.</p>
        <p>Diese Methode wird in Kombination mit anderen Behandlungsstrategien angewendet. Ihr Therapieplan wird individuell zusammengestellt.</p>
      </div>
    </div>
  </Layout>
)

export default MoxaPage;

export const query = graphql`
  query MoxaImageQuery {
    moxa: imageSharp(fluid: { originalName: {regex: "/moxa/" }}) {
      fluid(maxHeight: 580, cropFocus: ENTROPY ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

